import React from 'react';
import {Link} from 'react-router-dom';
import { denomination, decimals } from '../data/API';

class TransactionDetail extends React.Component {
  loopData = () => {
    if(this.props.jsonTx) {
      var txs = [];
      const dateNow = new Date();
      (this.props.jsonTx).forEach(tx => {
        var txDate = parseInt((dateNow-tx.txTime)/1000);
        if(txDate > 2592000){
          txDate = parseInt(txDate/2592000)+"mo";
        }else if(txDate > 86400){
          txDate = parseInt(txDate/86400)+"d";
        }else if(txDate > 3600){
          txDate = parseInt(txDate/3600)+"h";
        }else if(txDate > 60){
          txDate = parseInt(txDate/60)+"m";
        }else{
          txDate = txDate+"s"
        }
        txs.push(
          <Link to={`/tx/${tx.txHash}`}>
            <div>
              <div className="row">
                <div className="col-md-3 col-8">
                  {
                    tx.status === true ?
                      <p className="text-hash capitalize-text"><span className="badge bg-success">{tx.txMessage[0].type}</span></p>
                    :
                    <p className="text-hash capitalize-text"><span className="badge bg-danger">{tx.txMessage[0].type}</span></p>
                  }
                </div>
                <div className={`col-md-1 col-4`}>
                  <p><span className="badge bg-secondary"><span className="stopwatch">{txDate}</span><u style={{"display": "none"}} className="timestampSaver">{parseInt(tx.txTime)}</u> ago</span></p>
                </div>
                <div className={`col-md-2 col-6`}>
                  <p className="normal-text"><b>
                    {
                      parseInt(tx.txAmount) > 0 ?
                        `${(parseInt(tx.txAmount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} `
                      : tx.txAdditional ?
                        parseInt(tx.txAmount) > 0 ?
                          `${(parseInt(tx.txAdditional.amount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} `
                        : `${(0/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} `
                      : `${(0/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} `
                    }
                  </b>
                  <span className="badge bg-primary">
                  {
                    tx.txDenom ?
                      ` ${tx.txDenom.toUpperCase()}`
                    : ` ${denomination}`
                  }
                  </span>
                  </p>
                </div>
                <div className={`col-md-2 col-6`}>
                  {
                    Array.isArray(tx.txFee) ?
                      <p className="normal-text">Fee <b>{(parseInt(tx.txFee[0].amount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})}</b></p>
                    : <p className="normal-text">Fee <b>{(parseInt(tx.txFee)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})}</b></p>
                  }
                </div>
                <div className={`col-md-4 col-12`}>
                  <p className="text-hash content-table-title">{tx.txHash}</p>
                </div>
              </div>
            </div>
            <hr className="no-margin-hr" />
          </Link>
        )
      })
      return txs;
    }
    return txs;
  }
  render() {
    return (
      <div>
        {this.loopData()}
      </div>
    )
  }
}

export default TransactionDetail;