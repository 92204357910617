import React from 'react';
import { connect } from 'react-redux';
import {getBlocks, getPathName} from '../redux/actions/getData';
import person from '../person.svg';
import Tooltip from "@material-ui/core/Tooltip";

class Block extends React.Component {
  componentDidMount() {
    setInterval(() => {
      const timestampData = document.getElementsByClassName('timestampSaver');
      const stopwatchData = document.getElementsByClassName('stopwatch')
      const dateNow = new Date().getTime();
      var initLoop = 0;
      while (initLoop < timestampData.length) {
        var finalTimestamp = parseInt((dateNow-timestampData[initLoop].innerHTML)/1000);
        if(finalTimestamp > 3600){
          finalTimestamp = parseInt(finalTimestamp/3600)+"h";
        }else if (finalTimestamp > 60){
          finalTimestamp = parseInt(finalTimestamp/60)+"m";
        }else{
          finalTimestamp = finalTimestamp+"s";
        }
        stopwatchData[initLoop].innerHTML = finalTimestamp;
        initLoop++
      }
    }, 1000);
  }
  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  loopData = (numData, grid) => {
    const arrResult = [];
    const blocks = this.props.blockData;
    var totalData = 0;
    const dateNow = new Date();
    var colClass1 = "col-md-2 col-6"
    var colClass2 = "col-md-2 col-4"
    var colClass3 = "col-md-6 col-8"
    var colClass4 = "col-md-12"
    if(!grid){
      colClass1 = "col-md-2 col-6"
      colClass2 = "col-md-2 col-4"
      colClass3 = "col-md-3 col-8"
      colClass4 = "col-md-3"
    }
    if(blocks.length > 0) {
      while (totalData < numData) {
        var blockDate = parseInt((dateNow-blocks[totalData].blockTime)/1000);
        if(blockDate > 3600){
          blockDate = parseInt(blockDate/3600)+"h";
        }else if(blockDate > 60){
          blockDate = parseInt(blockDate/60)+"m";
        }else{
          blockDate = blockDate+"s"
        }
        var proposerName = "unknown";
        var operatorAddress;
        var imageUrl = person;
        if((this.props.activeValidators).length > 0){
          // eslint-disable-next-line no-loop-func
          (this.props.activeValidators).forEach((name) => {
            if(name.key === blocks[totalData].proposerKey){
              proposerName = name.info.moniker
              operatorAddress = name.operatorAddress
              if(name.imageUrl){
                imageUrl = name.imageUrl
              }
            }
          })
        }
        arrResult.push(
          <a href={"/block/"+blocks[totalData].blockHeight} onClick={() => this.changePath("/block")}>
            <div style={{padding: "6px"}}>
              <div className="row">
                <div className={colClass1}>
                  <p><span className="badge bg-success rounded-pill">{parseInt(blocks[totalData].blockHeight).toLocaleString()}</span></p>
                </div>
                <div className={colClass1}>
                  <span className="badge bg-secondary"><span className="stopwatch">{blockDate}</span><u style={{"display": "none"}} className="timestampSaver">{parseInt(blocks[totalData].blockTime)}</u> ago</span>
                </div>
                <div className={colClass2}>
                  <p className="normal-text"><i className="bi bi-list-nested"></i> <b>{blocks[totalData].numTx}</b> Txs</p>
                </div>
                <div className={colClass3}>
                  <p className="text-hash"><Tooltip interactive title={operatorAddress} placement="top-start"><a href={`/validator/${operatorAddress}`}><img className="image-validator" src={imageUrl} alt={operatorAddress}/><b>{proposerName}</b></a></Tooltip></p>
                </div>
                <div className={colClass4}>
                  <p className="text-hash"><b>{blocks[totalData].blockHash}</b></p>
                </div>
              </div>
            </div>
            <hr className="no-margin-hr" />
          </a>
        );
        totalData++;
      }
    }
    return arrResult;
  }
  render() {
    return (
      <div>
        {
          (this.props.activeValidators).length > 0 && (this.props.blockData).length > 0 ?
            this.loopData(this.props.total, this.props.grid)
          :
          <div className="content loading-div" data-theme={this.props.fullData.theme}>
            <div class="o1"></div>
            <div class="o2"></div>
            <div class="o3"></div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    blockData: state.user.latestBlock,
    activeValidators: state.user.activeValidators,
    pathName: state.user.pathName,
    fullData: state.user
  }
};

const mapDispatchToProps = {
  getBlocks,
  getPathName
}

export default connect(mapStateToProps, mapDispatchToProps)(Block);