import React from 'react';
import '../assets/css/styles.css';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import { connect } from 'react-redux';
import { getBlocks, getValidators, getSummary, getTransactions, getPrice, resetStatusSummary, resetStatusBlock, resetStatusTransaction, resetStatusValidator, resetStatusPrice, setIntervalStatus } from '../redux/actions/getData';
import {decimals} from '../data/API';

class Summary extends React.Component {
  componentDidMount() {
    this.props.getValidators();
    this.props.getBlocks();
    this.props.getSummary();
    this.props.getTransactions();
    this.props.getPrice();
    if(!this.props.fullData.blockInterval && !this.props.fullData.transactionInterval && !this.props.fullData.validatorInterval) {
      setInterval(() => {
        if(this.props.fullData.statusCodeBlock) {
          this.props.resetStatusBlock();
          this.props.getBlocks();
        }
        if(this.props.fullData.statusCodeTransaction) {
          this.props.resetStatusTransaction();
          this.props.getTransactions();
        }
        if(this.props.fullData.statusCodeValidator) {
          this.props.resetStatusValidator();
          this.props.getValidators();
        }
      }, 6000);
    }
    if(!this.props.fullData.summaryInterval){
      setInterval(() => {
        if(this.props.fullData.statusCodeSummary) {
          this.props.resetStatusSummary();
          this.props.getSummary();
        }
      }, 12000);
    }
    if(!this.props.fullData.priceInterval){
      setInterval(() => {
        if(this.props.fullData.statusCodePrice) {
          this.props.resetStatusPrice();
          this.props.getPrice();
        }
      }, 60000);
    }
    this.props.setIntervalStatus();
  }
  render(){
    return(
      <div className={this.props.home ? "summary-div" : "summary-div hide-mobile"} data-theme="dark">
        <div className="summary shadow-sm">
          <div className="row">
            <div className="col-md-3 col-6">
              <div className="title-div">
                <div className="title">
                  <p><i className="bi bi-boxes"></i> Latest Block</p>
                </div>
                <div className="title-value">
                  {(this.props.fullData.latestBlock).length > 0 ?
                    <p>{parseInt(this.props.fullData.latestBlock[0].blockHeight).toLocaleString()}</p>
                  : <p>0</p>}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="title-div">
                <div className="title">
                  <p><i className="bi bi-card-list"></i> Transaction(s)</p>
                </div>
                <div className="title-value">
                  {(this.props.fullData.latestBlock).length > 0 ?
                    <p className="summary-content">{this.props.fullData.latestBlock[0].numTx}</p>
                  : <p className="summary-content">0</p>}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="title-div">
                <div className="title">
                  <p><i className="bi bi-people"></i> Active Validators</p>
                </div>
                <div className="title-value">
                  <p>{(this.props.fullData.activeValidators).length}/{this.props.fullData.maxValidator}</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="title-div">
                <div className="title">
                  <p><i className="bi bi-lightning-charge"></i> Block Speed</p>
                </div>
                <div className="title-value">
                  {(this.props.fullData.latestBlock).length > 0 ?
                    <p>{parseFloat(((this.props.fullData.latestBlock[0].blockTime-this.props.fullData.blockFirst)/this.props.fullData.latestBlock[0].blockHeight)/1000).toFixed(3)}s</p>
                    : <p>0</p>}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="title-div">
                <div className="title">
                  <p><i className="bi bi-coin"></i> Total Supply</p>
                </div>
                <div className="title-value">
                  <p>
                    {
                      parseInt((this.props.fullData.totalSupply)/10**decimals) > 10**9 ?
                        `${parseInt((this.props.fullData.totalSupply)/10**decimals/10**9).toLocaleString()}Billion`
                      : parseInt((this.props.fullData.totalSupply)/10**decimals) > 10**decimals ?
                        `${parseInt((this.props.fullData.totalSupply)/10**decimals/10**decimals)}Million`
                      : parseInt((this.props.fullData.totalSupply)/10**decimals).toLocaleString()
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="title-div">
                <div className="title">
                  <p><i className="bi bi-info"></i> Bonded Coins</p>
                </div>
                <div className="title-value">
                  <p>
                    {
                      parseInt((this.props.fullData.bondedCoins)/10**decimals) > 10**9 ?
                        `${parseInt((this.props.fullData.bondedCoins)/10**decimals/10**9).toLocaleString()}Billion`
                      : parseInt((this.props.fullData.bondedCoins)/10**decimals) > 10**decimals ?
                        `${parseInt((this.props.fullData.bondedCoins)/10**decimals/10**decimals)}Million`
                      : parseInt((this.props.fullData.bondedCoins)/10**decimals).toLocaleString()
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="title-div">
                <div className="title">
                  <p><i className="bi bi-activity"></i> Price</p>
                </div>
                <div className="title-value">
                  <p>
                    {
                      isNaN(this.props.fullData.price) ?
                        "-"
                      : `$${parseFloat(this.props.fullData.price).toLocaleString(undefined, {maximumFractionDigits: 4})}`
                    }</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="title-div">
                <div className="title">
                  <p><i className="bi bi-currency-dollar"></i> Volume</p>
                </div>
                <div className="title-value">
                  <p>
                    {
                      isNaN(this.props.fullData.volume) ?
                        "-"
                      : `$${parseFloat(this.props.fullData.volume).toLocaleString(undefined, {maximumFractionDigits: 4})}`
                    }</p>
                </div>
              </div>
            </div>
            {
              this.props.home ?
              <> 
                <div className="col-6">
                  <div className="circular-div">
                    <CircularProgressbarWithChildren
                      value={parseInt(this.props.fullData.bondedCoins)/parseInt(this.props.fullData.totalSupply)*100}
                      styles={buildStyles({
                        pathColor: "#5755b9",
                        trailColor: "#212a2e"
                      })}
                    >
                      <p>Bonded Coins</p>
                      <strong>
                      {
                        this.props.fullData.bondedCoins > 0 && this.props.fullData.totalSupply > 0 ?
                          (parseInt(this.props.fullData.bondedCoins)/parseInt(this.props.fullData.totalSupply)*100).toFixed(2)
                        : 100
                      }%</strong>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
                <div className="col-6">
                  <div className="circular-div">
                    <CircularProgressbarWithChildren
                      value={this.props.fullData.inflation}
                      styles={buildStyles({
                        pathColor: "#5755b9",
                        trailColor: "#212a2e"
                      })}
                    >
                      <p>Inflation</p>
                      <strong>{parseInt(this.props.fullData.inflation).toFixed(2)}%</strong>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
              </>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

const mapDispatchToProps = {
  getBlocks,
  getValidators,
  getSummary,
  getTransactions,
  getPrice,
  resetStatusSummary,
  resetStatusBlock,
  resetStatusTransaction,
  resetStatusValidator,
  resetStatusPrice,
  setIntervalStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary);