import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Validator from '../components/Validator';
import '../assets/css/styles.css';
import { projectName } from '../data/API';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {getValidators, getSummary} from '../redux/actions/getData';

class Validators extends React.Component {
  componentDidMount(){
    document.title = `Validators | ${projectName} Scan`;
    this.props.getValidators();
    this.props.getSummary();
  }
  render() {
    return(
      <>
        <div className="summary-div" data-theme={this.props.fullData.theme}>
          <div className="summary shadow" style={{padding: "12px"}}>
            <BrowserRouter>
              <Switch>
                <Route exact path="/validators/inactive">
                  <div className="tab-div">
                    <Link to="/validators/active"><button type="button" className="tab-button" style={{borderBottom: "1.5px"}}>Active</button></Link>
                    <Link to="/validators/inactive"><button type="button" className="tab-button" style={{"borderColor": "red"}}>Inactive</button></Link>
                  </div>
                  <Validator total="100" status="inactive"/>
                </Route>
                <Route exact path="/validators/active">
                  <div className="tab-div">
                    <Link to="/validators/active"><button type="button" className="tab-button">Active</button></Link>
                    <Link to="/validators/inactive"><button type="button" className="tab-button" style={{borderBottom: "1.5px"}}>Inactive</button></Link>
                  </div>
                  <Validator total="100" status="active"/>
                </Route>
                <Route exact path="/validators">
                  <div className="tab-div">
                    <Link to="/validators/active"><button type="button" className="tab-button">Active</button></Link>
                    <Link to="/validators/inactive"><button type="button" className="tab-button" style={{borderBottom: "1.5px"}}>Inactive</button></Link>
                  </div>
                  <Validator total="100" status="active"/>
                </Route>
              </Switch>
            </BrowserRouter>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};
const mapDispatchToProps = {
  getValidators,
  getSummary
}

export default connect(mapStateToProps, mapDispatchToProps)(Validators);