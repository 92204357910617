import React from 'react';
import Summary from '../components/Summary';
import Block from '../components/Block';
import Transaction from '../components/Transaction';
import Validator from '../components/Validator';
import { projectName } from '../data/API';
import { connect } from 'react-redux';

class Home extends React.Component {
  state = {
    blockColumn: "col-md-6",
    blockTotal: 5,
    transactionDisplay: "block",
    blockGrid: true,
    blockArrow: "arrows-fullscreen",
    transactionColumn: "col-md-6",
    transactionTotal: 5,
    blockDisplay: "block",
    transactionGrid: true,
    transactionArrow: "arrows-fullscreen"
  }
  componentDidMount(){
    document.title = `${projectName} Block Explorer | ${projectName} Scan`;
  }
  render(){
    return(
      <>
        <Summary home />
        <div className="summary-div" data-theme={this.props.fullData.theme}>
          <div className="row">
            <div className={this.state.blockColumn} style={{display: this.state.blockDisplay}}>
              <div className="summary shadow-sm" style={{borderRadius: "4px", paddingTop: "12px", minHeight: "622px"}}>
                <strong className="title-text mx-6">Latest Block
                {
                  this.state.blockGrid ?
                    <i className={`bi bi-${this.state.blockArrow} grid-icon`} onClick={() => this.setState({blockColumn: "col-md-12", transactionDisplay: "none", blockGrid: false, blockTotal: 20, blockArrow: "arrows-angle-contract"})}></i>
                  : <i className={`bi bi-${this.state.blockArrow} grid-icon`} onClick={() => this.setState({blockColumn: "col-md-6", transactionDisplay: "block", blockGrid: true, blockTotal: 5, blockArrow: "arrows-fullscreen"})}></i>
                }
                </strong>
                <hr/>
                <Block total={this.state.blockTotal} grid={this.state.blockGrid} />
              </div>
            </div>
            <div className={this.state.transactionColumn} style={{display: this.state.transactionDisplay}}>
              <div className="summary shadow-sm" style={{borderRadius: "4px", paddingTop: "12px", minHeight: "622px"}}>
                <strong className="title-text mx-6">Latest Transaction
                {
                  this.state.transactionGrid ?
                    <i className={`bi bi-${this.state.transactionArrow} grid-icon`} onClick={() => this.setState({transactionColumn: "col-md-12", blockDisplay: "none", transactionGrid: false, transactionTotal: 20, transactionArrow: "arrows-angle-contract"})}></i>
                  : <i className={`bi bi-${this.state.transactionArrow} grid-icon`} onClick={() => this.setState({transactionColumn: "col-md-6", blockDisplay: "block", transactionGrid: true, transactionTotal: 5, transactionArrow: "arrows-fullscreen", sender: false, block: false, gas: false})}></i>
                }
                </strong>
                <hr/>
                <Transaction total={this.state.transactionTotal} grid={this.state.transactionGrid} sender={this.state.sender} block={this.state.block} gas={this.state.gas} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="summary shadow-sm" style={{borderRadius: "4px", paddingTop: "12px"}}>
                <strong className="title-text mx-6">Top Validator</strong>
                <hr/>
                <Validator total="10" status="active" pagination={true}/>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

export default connect(mapStateToProps)(Home);