import React from 'react';
import { denomination } from '../data/API';
import ReactTooltip from "react-tooltip";

class Label extends React.Component {
  render(){
    return(
      <>
      <ReactTooltip id="copy-text" type="dark">
        Copy
      </ReactTooltip>
      <ReactTooltip id="tooltip-text" type="dark">
        {this.props.tooltipLabel}
      </ReactTooltip>
      <div className="row">
        <div className={this.props.validator ? "col-12 col-md-4" : "col-12 col-md-2"}>
          <p className="content-table-title">{this.props.label}</p>
        </div>
        <div className={this.props.validator ? "col-12 col-md-8" : "col-12 col-md-10"}>
          <p className="content-table-text">
          {
            this.props.a ? 
              <a href={this.props.aTo} className="content-table-a">{this.props.value} </a>
            : this.props.badge ?
              <span className={`badge ${this.props.badgeType}`}>{this.props.value}</span>
            : this.props.value
          }
          {
            this.props.denom ?
            <>
              <span className="badge bg-primary"> {denomination}</span>
            </>
            : null
          }
          {
            this.props.percentage ? 
              ` (${this.props.percentageValue})`
            : null
          }
          {
            this.props.tooltip && this.props.tooltipLabel === "Copy" ?
            <>
              <span data-tip data-for="copy-text" id="copy-text"> {this.props.tooltipValue}</span>
            </>
            : this.props.tooltip ?
              <span data-tip data-for="tooltip-text"> {this.props.tooltipValue}</span>
            : null
          }
          </p>
        </div>
      </div>
      </>
    )
  }
}

export default Label;