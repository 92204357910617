import React from 'react';
import '../assets/css/styles.css';
import { apiURL, projectName } from '../data/API';
import moment from 'moment'
import Axios from 'axios';
import TransactionDetail from '../components/TransactionDetail';
import Label from '../components/Label';
import ReactTooltip from "react-tooltip";
import { connect } from 'react-redux';

class Block extends React.Component {
  state={}
  componentDidMount(){
    this.getData();
    this.getTxs();
    document.title = `Block #${parseInt(this.props.match.params.height).toLocaleString()} | ${projectName} Scan`;
  }
  componentDidUpdate(){
    if(this.state.height !== this.props.match.params.height) {
      this.getTxs()
      this.getData()
    }
  }
  getData = () => {
    Axios.get(`${apiURL}/blocks/detail?height=${this.props.match.params.height}`)
    .then(response => {
      this.setState({
        height: response.data.data.blockHeight,
        hash: response.data.data.blockHash,
        time: response.data.data.blockTime,
        previousHash: response.data.data.previousBlockHash,
        proposerMoniker: response.data.data.proposerAddress.moniker,
        proposerAddress: response.data.data.proposerAddress.operatorAddress,
        chainId: response.data.data.chainId,
        validators: response.data.data.validators,
        numValidators: response.data.data.numValidators,
      })
    })
  }
  getTxs = () => {
    Axios.get(`${apiURL}/txs/list?height=${this.props.match.params.height}`)
    .then(txs => {
      this.setState({
        txs: txs.data.data,
        numTxs: txs.data.data.length
      })
    })
  }
  renderValidator(){
    if(this.state.validators){
      const validator = (this.state.validators).map(validator => {
        return(
          <div className="col-6 col-md-3">
            <a href={`/validator/${validator.operatorAddress}`}>
              <div className="summary background-block-validator">
                <p className="text-hash mb-0">{validator.moniker}</p>
              </div>
            </a>
          </div>
        )
      })
      return validator;
    }
  }
  render() {
    return(
      <div className="summary-div" data-theme={this.props.fullData.theme}>
        {
          this.state.hash && this.state.height === this.props.match.params.height ?
          <>
          <ReactTooltip id="date-text" type="dark">
            {moment(this.state.time).format('DD MMMM YYYY, hh:mm:ss')}
          </ReactTooltip>
          <div className="summary shadow-sm summary-12">
            <strong className="title-text mx-0 d-block mb-2">Block Details</strong>
            <span class="text-inline no-margin-text badge rounded-pill bg-success mb-2"><i class="bi bi-hash"></i> {parseInt(this.state.height).toLocaleString()}</span>
            <div class="text-inline vr mx-2"></div>
            <span class="text-inline no-margin-text mb-2 small-text" data-tip data-for="date-text"><i class="bi bi-clock-history"></i> {moment(this.state.time).fromNow()}</span>
            <hr class="no-margin-hr" />
            <Label label="Hash" value={this.state.hash} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-stickies" onClick={() => {navigator.clipboard.writeText(this.state.hash)}}></i>} tooltip />
            <Label label="Transaction(s)" value={parseInt(this.state.numTxs).toLocaleString()} badge badgeType="bg-primary" />
            <Label label="Chain ID" value={this.state.chainId} badge badgeType="bg-success" />
            <Label label="Proposer" value={this.state.proposerMoniker} to={`/validator/${this.state.proposerAddress}`} a />
            <Label label="Validators" value={this.state.numValidators} />
            <Label label="Parent Hash" value={this.state.previousHash} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-stickies" onClick={() => {navigator.clipboard.writeText(this.state.previousHash)}}></i>} aTo={`/block/${parseInt(this.state.height)-1}`} a hrefoltip />
          </div>
          {
            this.state.txs ?
              this.state.numTxs > 0 ?
              <>
                <div className="summary shadow-sm summary-12">
                  <strong className="mx-0 title-text">Transactions</strong>
                  <hr/>
                  <div className="overflow-custom txs-block">
                  {
                    this.state.txs ?
                      <TransactionDetail jsonTx={this.state.txs}/>
                    : null
                  }
                  </div>
                </div>
              </>
              :
              <div></div>
            :
            <div className="summary shadow-sm" style={{"textAlign": "center", height: "150px"}}>
              <div style={{margin: "0 auto", marginTop: "45px"}}>
                <div class="o1"></div>
                <div class="o2"></div>
                <div class="o3"></div>
              </div>
            </div>
          }
          <div className="summary shadow-sm summary-12">
            <strong className="title-text mx-0">Validators</strong>
            <hr/>
            <div className="overflow-custom validator-block">
              <div className="row">
                {this.renderValidator()}
              </div>
            </div>
          </div>
          </>
          :
          <div className="summary shadow-sm" style={{"textAlign": "center", height: "622px"}}>
            <div style={{margin: "0 auto", marginTop: "275px"}}>
              <div class="o1"></div>
              <div class="o2"></div>
              <div class="o3"></div>
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

export default connect(mapStateToProps)(Block);