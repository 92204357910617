import React from 'react';
import { connect } from 'react-redux';
import {getTransactions, getPathName} from '../redux/actions/getData';
import { denomination, decimals } from '../data/API';

class Transaction extends React.Component {
  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  loopData = (numData, grid) => {
    if((this.props.fullData.latestTransaction).length > 0) {
      var totalData = 0;
      var txs = [];
      const dateNow = new Date();
      var colClass1 = "col-md-1 col-5"
      var colClass2 = "col-md-2 col-6"
      var colClass3 = "col-md-4 col-12"
      if(grid){
        colClass1 = "col-md-3 col-5"
        colClass2 = "col-md-3 col-6"
        colClass3 = "col-12"
      }
      (this.props.fullData.latestTransaction).forEach(tx => {
        if(totalData < numData) {
          var txDate = parseInt((dateNow-tx.txTime)/1000);
          if(txDate > 3600){
            txDate = parseInt(txDate/3600)+"h";
          }else if(txDate > 60){
            txDate = parseInt(txDate/60)+"m";
          }else{
            txDate = txDate+"s"
          }
          txs.push(
            <a href={`/tx/${tx.txHash}`} onClick={() => this.changePath("/tx")}>
              <div style={{padding: "6px"}}>
                <div className="row">
                  <div className="col-md-3 col-7">
                    {
                      tx.status === true ?
                        <p className="text-hash capitalize-text"><span className="badge bg-success rounded-pill">{tx.txMessage[0].type}</span></p>
                      :
                      <p className="text-hash capitalize-text"><span className="badge bg-danger rounded-pill">{tx.txMessage[0].type}</span></p>
                    }
                  </div>
                  <div className={colClass1}>
                    <span className="badge bg-secondary"><span className="stopwatch">{txDate}</span><u style={{"display": "none"}} className="timestampSaver">{parseInt(tx.txTime)}</u> ago</span>
                  </div>
                  <div className={colClass2}>
                    <p className="normal-text"><b>
                      {
                        parseInt(tx.txAmount) > 0 ?
                          `${(parseInt(tx.txAmount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} `
                        : tx.txAdditional ?
                          parseInt(tx.txAmount) > 0 ?
                            `${(parseInt(tx.txAdditional.amount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} `
                          : `${(0/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} `
                        : `${(0/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} `
                      }
                    </b>
                    <span className="badge bg-primary">
                    {
                      tx.txDenom ?
                        `${tx.txDenom.toUpperCase()}`
                      : `${denomination}`
                    }
                    </span>
                    </p>
                  </div>
                  <div className={colClass2}>
                    {
                      Array.isArray(tx.txFee) ?
                      <p className="normal-text">Fee <b>{(parseInt(tx.txFee[0].amount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})}</b></p>
                    : <p className="normal-text">Fee <b>{(parseInt(tx.txFee)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})}</b></p>
                    }
                  </div>
                  <div className={colClass3}>
                    <p className="text-hash"><b>{tx.txHash}</b></p>
                  </div>
                </div>
              </div>
              <hr className="no-margin-hr" />
            </a>
          )
        }
        totalData++;
      })
    }
    return txs;
  }
  render() {
    return (
      <div>
        {
          (this.props.fullData.latestTransaction).length > 0 ?
            <div>
              {this.loopData(this.props.total, this.props.grid)}
            </div>
          :
          <div className="content loading-div" data-theme={this.props.fullData.theme}>
            <div class="o1"></div>
            <div class="o2"></div>
            <div class="o3"></div>
          </div>
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    fullData: state.user,
    pathName: state.user.pathName
  }
};

const mapDispatchToProps = {
  getTransactions,
  getPathName,
}

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);