import React from 'react';
import '../assets/css/styles.css';
import { apiURL, denomination, projectName, decimals } from '../data/API';
import Axios from 'axios';
import QRCode from "react-qr-code";
import Tooltip from "@material-ui/core/Tooltip";
import { PieChart } from 'react-minimal-pie-chart';
import moment from 'moment';
import person from '../person.svg';
import TransactionDetail from '../components/TransactionDetail';
import { connect } from 'react-redux';
import { getPrice } from '../redux/actions/getData';
import ReactTooltip from "react-tooltip";

class Account extends React.Component {
  state={
    qr: false
  }
  constructor(props) {
    super(props);
    this.QRHandler = this.QRHandler.bind(this);
  } 
  componentWillMount(){
    this.props.getPrice();
    this.getData();
    document.title = `Address ${this.props.match.params.address} | ${projectName} Scan`;
  }
  componentDidUpdate(){
    if(this.state.address !== this.props.match.params.address) {
      this.getData();
    }
  }
  QRHandler(){
    if(this.state.qr){
      this.setState({qr: false})
    }else{
      this.setState({qr: true})
    }
  }
  getData = async() => {
    Axios.get(`${apiURL}/balance/balance?address=${this.props.match.params.address}`)
    .then(response => {
      if(response.data.result){
        var balance;
        (response.data.data.balance).forEach(bal => {
          if(bal.denom.toLocaleLowerCase() === denomination.toLocaleLowerCase()){
            balance = bal.amount
          }
        })
        this.setState({
          checkBalance: true,
          address: response.data.data.address,
          token: response.data.data.balance,
          balance: balance ? balance : 0,
          sequence: response.data.data.sequence,
          rewards: response.data.data.rewards,
          delegate: response.data.data.delegate,
          undelegate: response.data.data.undelegate,
          commission: response.data.data.commission
        })
      }else{
        console.log("Failed to fetch balance")
      }
    })
    .catch(err => {
      console.log("Failed to fetch balance")
    })
    Axios.get(`${apiURL}/balance/delegations?address=${this.props.match.params.address}`)
    .then(response => {
      if(response.data.result){
        this.setState({
          delegates: response.data.data,
          checkDelegations: true
        })
      }else{
        console.log("Failed to fetch delegation")
      }
    })
    Axios.get(`${apiURL}/balance/undelegations?address=${this.props.match.params.address}`)
    .then(response => {
      if(response.data.result){
        this.setState({
          undelegates: response.data.data,
          checkUndelegations: true
        })
      }else{
        console.log("Failed to fetch undelegation")
      }
    })
    .catch(err => {
      console.log("Failed to fetch transactions")
    })
    Axios.get(`${apiURL}/txs/list?address=${this.props.match.params.address}`)
    .then(response => {
      if(response.data.result){
        this.setState({
          txs: (response.data.data).reverse(),
          checkTxs: true
        })
      }else{
        console.log("Failed to fetch transaction")
      }
    })
    .catch(err => {
      console.log("Failed to fetch transactions")
    })
  }
  renderDelegate = () => {
    if(this.state.delegates){
      var delegates = [];
      (this.state.delegates).forEach(delegate => {
        var image = person;
        if(delegate.imageUrl){
          image = delegate.imageUrl
        }
        delegates.push(
          <div>
            <p className="text-hash mb-2"><Tooltip interactive title={delegate.operatorAddress} placement="top-start"><a href={`/validator/${delegate.operatorAddress}`}><img className="image-validator" src={image} alt={delegate.operatorAddress}/>{delegate.moniker}</a></Tooltip></p>
            <div className="row">
              <div className="col-xl-1 col-4 balance-text">
                <p>Total</p>
              </div>
              <div className="col-xl-5 col-8 balance-text text-center">
                <p><b>{(parseInt(delegate.balance)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})}</b> {denomination}</p>
              </div>
              <div className="col-xl-1 col-4 balance-text">
                <p>Reward</p>
              </div>
              <div className="col-xl-5 col-8 balance-text text-center">
                <p><b>{(parseInt(delegate.reward)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})}</b> {denomination}</p>
              </div>
            </div>
            <hr/>
          </div>
        )
      })
      return delegates
    }
  }
  renderUndelegate = () => {
    if(this.state.undelegates){
      var undelegates = [];
      (this.state.undelegates).forEach(undelegate => {
        var image = person;
        if(undelegate.imageUrl){
          image = undelegate.imageUrl
        }
        undelegates.push(
          <div>
            <p className="text-hash mb-2"><Tooltip interactive title={undelegate.operatorAddress} placement="top-start"><a href={`/validator/${undelegate.operatorAddress}`}><img className="image-validator" src={image} alt={undelegate.operatorAddress}/>{undelegate.moniker}</a></Tooltip></p>
            <div className="row">
              <div className="col-xl-1 col-4" >
                <p className="normal-text mb-1">Total</p>
              </div>
              <div className="col-xl-5 col-8" >
                <p className="normal-text mb-1"><b>{(parseInt(undelegate.balance)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})}</b> {denomination}</p>
              </div>
              <div className="col-xl-3 col-4" >
                <p className="normal-text mb-1">Start Height</p>
              </div>
              <div className="col-xl-3 col-8" >
                <p className="normal-text mb-1">{parseInt(undelegate.startHeight).toLocaleString()}</p>
              </div>
              <div className="col-xl-2 col-4" >
                <p className="normal-text mb-0">End Time</p>
              </div>
              <div className="col-xl-10 col-8" >
                <p className="normal-text mb-0">{`${moment(undelegate.endTime).format("DD/MM/YY, HH:mm")} (${moment(undelegate.endTime).fromNow()})`}</p>
              </div>
            </div>
            <hr/>
          </div>
        )
      })
      return undelegates
    }
  }

  render(){
    return(
      <div className="summary-div" data-theme={this.props.fullData.theme}>
        {
          this.state.checkBalance === true && this.state.address === this.props.match.params.address ?
          <>
          <ReactTooltip id="copy-text" type="dark">
            Copy
          </ReactTooltip>
          <div className="row">
            <div className="col-md-3">
              <div className="summary shadow-sm bg-success p-2">
                <button className="show-qr text-light rounded-pill" onClick={this.QRHandler}>
                  <i className="bi bi-qr-code-scan"></i> 
                  {
                    this.state.qr ?
                      <> Hide QR Code <i className="bi bi-chevron-double-up"></i></>
                    : <> Show QR Code <i className="bi bi-chevron-double-down"></i></>
                  }
                </button>
                {
                  this.state.qr ?
                    <div className="qr-code text-center mb-1 mt-1">
                      <QRCode value={this.props.match.params.address} bgColor="#198754" fgColor="#fff" size="150" />
                    </div>
                  : null
                }
                <p className="content-table-title text-light no-margin-text">Address</p>
                <p className="content-table-text text-light no-margin-text">{this.props.match.params.address} <i className="copy-icon bi bi-stickies" data-tip data-for="copy-text" id="copy-text" onClick={() => {navigator.clipboard.writeText(this.props.match.params.address)}}></i></p>
                {
                  !this.state.qr ?
                    <>
                    <ReactTooltip id="info-tip" type="dark">
                      Amount displayed in decimal {decimals}
                    </ReactTooltip>
                    <p className="text-white mt-3 content-table-title">Assets <span className="badge bg-secondary rounded-pill">{this.state.token.length}</span> <i className="bi bi-info" data-tip data-for="info-tip"></i></p>
                    <hr className="border border-dark hr-no-margin-top mb-2" />
                    <div className="token-list">
                      {
                        (this.state.token).map(balance => {
                          return (
                            <>
                            <div className="row">
                              <div className="col-4">
                                <p className="text-white text-left p-title">{balance.denom}</p>
                              </div>
                              <div className="col-8">
                                <p className="text-white text-right p-title">{((parseInt(balance.amount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6}))}</p>
                              </div>
                            </div>
                            </>
                          )
                        })
                      }
                    </div>
                    </>
                  : null
                }
              </div>
            </div>
            <div className="col-md-9">
              <div className="summary shadow-sm">
                <strong>Balance Details</strong>
                <hr/>
                <div className="row">
                  <div className="col-md-2 text-center">
                    <div className="pie-chart">
                      <PieChart
                        data={[
                          { title: 'Available', value: parseInt(this.state.balance)/10**decimals, color: '#2A9D8F' },
                          { title: 'Delegated', value: parseInt(this.state.delegate)/10**decimals, color: '#F4A261' },
                          { title: 'Reward', value: parseInt(this.state.rewards)/10**decimals, color: '#264653' },
                          { title: 'Unbonding', value: parseInt(this.state.undelegate)/10**decimals, color: '#E76F51' },
                          { title: 'Commission', value: parseInt(this.state.commission)/10**decimals, color: '#5154E8' },
                        ]}
                        lineWidth = {30}
                        animate
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-3">
                        <p className="content-table-title"><i style={{"color": "#2A9D8F"}} className="bi bi-circle-fill"></i> Available</p>
                      </div>
                      <div className="col-md-9">
                        <p className="content-table-text">{(parseInt(this.state.balance)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#2A9D8F"}}>{denomination}</b> <span className="badge bg-primary"><i className="bi bi-currency-dollar"></i>{isNaN(this.props.fullData.price) ? 0 : (parseFloat(this.props.fullData.price)*(parseInt(this.state.balance)/10**decimals)).toLocaleString(undefined, {maximumFractionDigits: 6})}</span></p>
                      </div>
                      <div className="col-md-3">
                        <p className="content-table-title"><i style={{"color": "#F4A261"}} className="bi bi-circle-fill"></i> Delegated</p>
                      </div>
                      <div className="col-md-9">
                        <p className="content-table-text">{(parseInt(this.state.delegate)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#F4A261"}}>{denomination}</b> <span className="badge bg-primary"><i className="bi bi-currency-dollar"></i>{isNaN(this.props.fullData.price) ? 0 : (parseFloat(this.props.fullData.price)*(parseInt(this.state.delegate)/10**decimals)).toLocaleString(undefined, {maximumFractionDigits: 6})}</span></p>
                      </div>
                      <div className="col-md-3">
                        <p className="content-table-title"><i style={{"color": "#264653"}} className="bi bi-circle-fill"></i> Reward</p>
                      </div>
                      <div className="col-md-9">
                        <p className="content-table-text">{(parseInt(this.state.rewards)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#264653"}}>{denomination}</b> <span className="badge bg-primary"><i className="bi bi-currency-dollar"></i>{isNaN(this.props.fullData.price) ? 0 : (parseFloat(this.props.fullData.price)*(parseInt(this.state.rewards)/10**decimals)).toLocaleString(undefined, {maximumFractionDigits: 6})}</span></p>
                      </div>
                      <div className="col-md-3">
                        <p className="content-table-title"><i style={{"color": "#E76F51"}} className="bi bi-circle-fill"></i> Undelegating</p>
                      </div>
                      <div className="col-md-9">
                        <p className="content-table-text">{(parseInt(this.state.undelegate)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#E76F51"}}>{denomination}</b> <span className="badge bg-primary"><i className="bi bi-currency-dollar"></i>{isNaN(this.props.fullData.price) ? 0 : (parseFloat(this.props.fullData.price)*(parseInt(this.state.undelegate)/10**decimals)).toLocaleString(undefined, {maximumFractionDigits: 6})}</span></p>
                      </div>
                      <div className="col-md-3">
                        <p className="content-table-title"><i style={{"color": "#5154E8"}} className="bi bi-circle-fill"></i> Commission</p>
                      </div>
                      <div className="col-md-9">
                        <p className="content-table-text">{(parseInt(this.state.commission)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} <b className="title-underline" style={{"textDecorationColor": "#E76F51"}}>{denomination}</b> <span className="badge bg-primary"><i className="bi bi-currency-dollar"></i>{isNaN(this.props.fullData.price) ? 0 : (parseFloat(this.props.fullData.price)*(parseInt(this.state.undelegate)/10**decimals)).toLocaleString(undefined, {maximumFractionDigits: 6})}</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            !this.state.checkDelegations && !this.state.checkUndelegations ?
              <div className="summary shadow-sm" style={{"textAlign": "center", "height": "300px"}}>
                <div style={{margin: "0 auto", marginTop: "125px"}}>
                  <div class="o1"></div>
                  <div class="o2"></div>
                  <div class="o3"></div>
                </div>
              </div>
            :
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="summary shadow-sm" style={{"padding": "12px"}}>
                    <strong style={{fontSize: "17px", marginLeft: "0"}}>Delegated <b style={{"opacity": "0.75", "fontWeight": "normal", "fontSize": "13px"}}>{this.state.delegates ? ((this.state.delegates).length) : 0}</b></strong>
                    <hr/>
                    <div className="overflow-custom" style={{"height": "200px", "overflowX": "hidden","overflowY":"auto",}}>
                      {this.renderDelegate()}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="summary shadow-sm" style={{padding: "12px"}}>
                    <strong style={{fontSize: "17px", marginLeft: "0"}}>Unbonding <b style={{"opacity": "0.75", "fontWeight": "normal", "fontSize": "13px"}}>{this.state.undelegates ? ((this.state.undelegates).length) : 0}</b></strong>
                    <hr/>
                    <div className="overflow-custom" style={{"height": "200px", "overflowX": "hidden","overflowY":"auto",}}>
                      {this.renderUndelegate()}
                    </div>
                  </div>
                </div>
              </div>
              </>
          }
          {
            this.state.checkTxs ?
              <div className="summary shadow-sm overflow-custom" style={{"padding":"12px"}}>
                <strong style={{fontSize: "17px", marginLeft: "0"}}>Transaction</strong>
                <hr/>
                <div className="overflow-custom" style={{"maxHeight": "400px", "overflowY": "auto", "overflowX": "hidden"}}>
                  <TransactionDetail jsonTx={this.state.txs} address={this.props.match.params.address}/>
                </div>
              </div>
            :
            <div className="summary shadow-sm" style={{"textAlign": "center", "height": "300px"}}>
              <div style={{margin: "0 auto", marginTop: "125px"}}>
                <div class="o1"></div>
                <div class="o2"></div>
                <div class="o3"></div>
              </div>
            </div>
          }
          </>
          : 
          <div className="summary shadow-sm" style={{"textAlign": "center", "height": "622px"}}>
            <div style={{margin: "0 auto", marginTop: "275px"}}>
              <div class="o1"></div>
              <div class="o2"></div>
              <div class="o3"></div>
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

const mapDispatchToProps = {
  getPrice
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);